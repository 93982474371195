<template>
<div class="container-fluid mt-5 pt-5">
      <div class="page-breadcrumb">
        <div class="col-12">
            <div class="row">
                <div class="col-7 align-self-center pl-4">
                    <h3 class="page-title text-truncate text-dark font-weight-medium mb-1">config. e-sms</h3>
                    <div class="d-flex align-items-center">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb m-0 p-0">
                                <li class="breadcrumb-item"><a href="?app=dashboard.php">dashboard / assistência técnica / config. e-sms</a></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid qsuite-c-fluid-0">
        <div class="col-12">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body pt-4">
                            <div class="tab-content">
                                <div class="tab-pane show active" id="boasvindas">
                                    <form action="" class="px-2 needs-validation" novalidate="">
                                        <div class="form-body">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label>Mensagem</label>
                                                        <textarea class="form-control" rows="7" placeholder=""></textarea>
                                                    </div>
                                                    <small id="name1" class="badge badge-danger badge-info form-text text-white float-left">Até 120 caracteres</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-actions">
                                            <div class="text-right">
                                                <button type="submit" class="btn btn-info">Salvar</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import EnterpriseConfigService from "@/services/resources/EnterpriseConfigService";
const service = EnterpriseConfigService.build();

export default {
  data() {
    return {
      name: null,
      color: null,
      status: [],
      search: ''
    };
  },
  methods: {
    goSearch(){
        if(this.search.length == 0)
            this.fetch();

        this.status = this.status.filter(elem => {
            return elem.name.toLowerCase().includes(this.search.toLowerCase());
        });
    },
    save(){
      this.$validator.validateAll().then((result) => {
        if (result) {

        let data = {
          name: this.name,
          color: this.color,
          type: 'STATUS'
        }
        
          service
          .create(data)
            .then(resp => {
                this.$bvToast.toast('Sua configuração foi criada com sucesso!', {
                  title: 'Configuração criada',
                  autoHideDelay: 5000,
                  type: 'success'
                })
                this.fetch();
            })
            .catch(err => {
              console.log(err)
            })
          }
      });
    },
    checkField(){
        return this.status.filter(elem => {
            return elem.name.length == 0;
        }).length;
    },
    update(){

        if(this.checkField()){
            this.$bvToast.toast('Algum campo está inválido, por favor, verifique.', {
                title: 'Campo inválido',
                autoHideDelay: 5000,
                type: 'danger'
            })
            return;
        }

        let data = {
          id: 0,
          config: this.status
        }
    
        service
        .update(data)
        .then(resp => {
            this.$bvToast.toast('Sua configuração foi atualizada com sucesso!', {
                title: 'Configuração atualizada',
                autoHideDelay: 5000,
                type: 'success'
            })
        })
        .catch(err => {
            console.log(err)
        })
    },
    fetch(){
        let data = {
            type: 'STATUS'
        }

        service
        .search(data)
        .then(resp => {
            this.status = resp;
        })
        .catch(err => {
            console.log(err)
        })
    }

  },
  mounted() {
      this.fetch();
  },
};
</script>
<style scoped lang="scss">
.bg{
  background: #fff;
  padding: 10px;
  border: 1px #ddd solid;
  border-radius: 5px;
}
</style>